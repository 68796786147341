<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3" v-if="isAllowedTo('SupplierCentral/getAllSupplierIntegrationConfig')">
        <grid-header :quickSearch.sync="filters.search">
            Supplier Integrations
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'SupplierIntegrationsOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            options: {
                columns: {
                    'Supplier': {
                        field: 'supplierName',
                        sortable: true,
                        link: (value, data) => this.getSupplierIntegrationDetailRoute(data),
                    },
                    'Status': {
                        field: 'status',
                        statusChip: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-eye',
                        text: 'View',
                        to: ({ data }) => this.getSupplierIntegrationDetailRoute(data),
                    },
                ],

                quickSearchColumns: ['agreementName'],
            },
            url: CONFIG.API.ROUTES.SUPPLIER_INTEGRATIONS.OVERVIEW,
            filters: {
                search: '',
            },
            supplierPopupActive: false,
        }
    },
    methods: {
        getSupplierIntegrationDetailRoute (params) {
            return {
                name: 'SupplierIntegrationDetail',
                params: {
                    supplierIntegrationConfigId: params.supplierIntegrationConfigId,
                },
            }
        },
    },
}
</script>
